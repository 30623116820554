import type {UseFetchOptions} from 'nuxt/app'
import {RuntimeConfig} from "nuxt/schema";
import {DateTime} from "next-auth/providers/kakao";
import {$fetch} from "ofetch";

export function useApi<T = any>(url: string, options: UseFetchOptions<T> = {}, ofetch = false) {
    const config: RuntimeConfig = useRuntimeConfig();
    const {data} = useAuth();
    const auth = data.value?.auth;

    const authorization: string = !!auth
        ? `${auth.type} ${auth.token}`
        : 'Basic ' + btoa("public:" + config.public.apiSecurityPublicSecurityKey)

    options = {
        key: url,
        baseURL: config.public.apiUrl,
        headers: {
            'Accept': 'application/json',
            'Authorization': authorization,
        },
        ...options,
    }

    if (options?.method && ['POST', 'PUT', 'PATCH'].includes(options.method.toString())) {
        options = {
            headers: {
                'Content-Type': 'application/json',
            },
            ...options,
        };
    }

    if (ofetch) {
        return $fetch(url, options);
    }

    return useFetch(url, options);
}

export interface PositionPayload {
    latitude: Number,
    longitude: Number,
    radius?: Number,
}

export interface ReplaceAddressPayload {
    country: string,
    postalCode: string,
    city: string,
    street?: string,
    building?: string,
    section?: string,
    province?: string,
    district?: string,
    position: PositionPayload,
}

export interface CreatePostPayload {
    id: string,
    category: string,
    title: string,
    content: string,
    occurredAt: DateTime,
    tags: Array<string>,
    images: Array<string>,
    location?: string,
    locationDescription?: string,
}

export interface PublicPostViewObject {
    id: string,
    category: string,
    title: string,
    content: string,
    location?: string,
    locationDescription?: string,
    images: Array<string>,
    tags: Array<string>,
    author: string,
    occurredAt: string,
    createdAt: string,
}
